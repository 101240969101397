<template>
  <LoadScript
    name="one-trust-cookie-helper"
    :src="oneTrustUrl"
    :attributes="{ 'data-domain-script': dataDomainScript, 'data-document-language': 'true' }"
    @loaded="main"
  />
</template>

<script>
import LoadScript from '../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';
export default {
  name: 'OneTrustCookieHelper',

  components: {
    LoadScript,
  },

  props: {
    stateCodes: { type: Array, required: true },
    countryCodes: { type: Array, required: true },
    dataDomainScript: { type: String, required: true },
    siteHasOptimizely: { type: Boolean, required: true, default: false },
    oneTrustUrl: { type: String, required: true, default: '' },
  },

  methods: {
    main() {
      this.checkConsent();

      if (this.siteHasOptimizely) {
        this.checkOptimizely();
      }
    },

    async waitForResource(target, resource, args) {
      const maxChecks = 50;
      for (let counter = 0; counter < maxChecks; counter++) {
        if (target === 'window' && window[resource]) return;
        if (target === 'function' && resource(args)) return resource(args);
        if (!target && resource) return;

        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      throw new Error('Maximum number of checks reached for resource ', resource);
    },

    getCookie(name) {
      let cookie = {};
      document.cookie.split(';').forEach(function (el) {
        let split = el.split('=');
        cookie[split[0].trim()] = split.slice(1).join('=');
      });
      return cookie[name];
    },

    setCookie(c_name, value, exdays) {
      let exdate = new Date();
      exdate.setDate(exdate.getDate() + exdays);
      let c_value =
        encodeURIComponent(value) +
        (exdays == null ? '' : '; expires=' + exdate.toUTCString() + '; path=/;');
      document.cookie = c_name + '=' + c_value;
    },

    checkConsent() {
      const ocCookie = this.getCookie('OptanonConsent');
      const oabcCookie = this.getCookie('OptanonAlertBoxClosed');
      const frCookie = this.getCookie('ForceReconsent');

      let canClearOptanonCookie = oabcCookie != null && ocCookie != null && frCookie == null;

      this.waitForResource('window', 'OneTrust').then(() => {
        const currentState = window.OneTrust.getGeolocationData().state;
        const currentCountry = window.OneTrust.getGeolocationData().country;
        let inStateToCheck =
          this.countryCodes.includes(currentCountry) && this.stateCodes.includes(currentState);

        if (frCookie == null && inStateToCheck) {
          this.setCookie('ForceReconsent', `${currentCountry}-${currentState}`, 365);

          if (canClearOptanonCookie) {
            window.OneTrust.RejectAll();
            this.setCookie('OptanonConsent', '', -1);
            this.setCookie('OptanonAlertBoxClosed', '', -1);
            location.reload();
          }
        }
      });
    },

    checkPermissionGroups() {
      const cookieValue = this.getCookie('OptanonConsent');
      const decodedcookieValue = decodeURIComponent(cookieValue);
      const keyValuePairs = decodedcookieValue.split('&');
      return keyValuePairs.find((pair) => pair.startsWith('groups='));
    },

    checkOptimizely() {
      this.waitForResource('function', this.checkPermissionGroups).then((response) => {
        let regex = /(?<![0-9])3:1/;
        const consentGroups = response.split('=')[1].split(',');
        if (!regex.test(consentGroups)) {
          window['optimizely'].push({
            type: 'disable',
          });
        }
      });
    },
  },
};
</script>
