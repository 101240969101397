<template>
  <article
    ref="card"
    :class="[
      'oap-card',
      `oap-card--type-${item.type || type}`,
      { 'oap-card--is-mex': item.isMex },
      { 'oap-card--has-buy-now': item.buyNowUrl },
      ...layoutsClasses,
    ]"
    :style="{ order: positionOnGrid }"
    @keyup.esc="$refs['back-link'].blur()"
    @click="gsqTagging"
  >
    <div class="oap-card__content">
      <div class="oap-card__front">
        <div v-if="headerTitle" class="oap-card__header">{{ headerTitle }}</div>
        <p v-else-if="item.label" class="oap-card__label">{{ item.label }}</p>

        <OapBadge v-if="item.beautySeal" :badge="item.beautySeal" />

        <div v-if="item.image" class="oap-card__thumbnail">
          <MediaImage
            :class="[item.image.packshotClass]"
            :filename="item.image.fileName"
            :alt="item.image.alt"
            :resolutions="item.image.resolutions"
            :lazy="true"
            :fallback-size="item.image.fallbackSize"
            :sizes="item.image.sizes"
            :aria-hidden="true"
          >
          </MediaImage>

          <div v-if="item.hasBrackets" class="oap-card__brackets">
            <div class="oap-card__brackets-top"></div>
            <div class="oap-card__brackets-bottom"></div>
          </div>

          <transition name="oap-card__fade-in">
            <div v-if="item.video && playing" class="oap-card__video">
              <video ref="video" :src="item.video" playsinline muted loop></video>
            </div>
          </transition>
        </div>

        <header>
          <OapProductShadeList v-if="item.shades" :shades="item.shades"></OapProductShadeList>

          <div v-if="item.title || item.brand" class="oap-card__heading">
            <span v-if="item.logo" class="oap-card__logo-wrapper">
              <img
                :data-src="item.logo.src"
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                :alt="item.logo.alt"
                class="oap-card__logo lazyload"
              />
            </span>
            <template v-if="item.hasContentHeading">
              <h2>
                <a
                  ref="link"
                  class="oap-card__link"
                  :class="item.additionalLinkClass"
                  :href="item.url"
                  :title="linkTitle"
                >
                  <span v-if="showBrand" class="oap-card__subtitle oap-card--drawer">
                    {{ item.brand }}
                  </span>
                  <span v-if="item.title" class="oap-card__title">{{ item.title }}</span>
                </a>
              </h2>
            </template>
            <template v-else>
              <p role="heading" aria-level="3">
                <a
                  ref="link"
                  class="oap-card__link"
                  :href="selectiveRetailerURL ? `${item.url}${selectiveRetailerURL}` : item.url"
                  :class="item.additionalLinkClass"
                  :title="linkTitle"
                >
                  <span v-if="showBrand" class="oap-card__subtitle oap-card--drawer">
                    {{ item.brand }}
                  </span>
                  <span v-if="item.title" class="oap-card__title">{{ item.title }}</span>
                </a>
              </p>
            </template>
          </div>
        </header>

        <p v-if="item.text" class="oap-card__text" :title="item.text">{{ item.text }}</p>

        <div class="oap-card__space-filler"></div>

        <footer v-if="hasFooterItem" class="oap-card__footer oap-card--drawer">
          <div class="oap-card__price">
            {{ price }}
          </div>

          <div
            v-if="(item.rating && item.rating.bv) || isTypeProduct"
            class="oap-card__rating"
            :class="{ '-count-visible': displayReviewCount }"
          >
            <template v-if="item.rating && item.rating.bv">
              <div class="oap-card__rating-list">
                <span
                  v-for="n in item.rating.max"
                  :key="n"
                  class="oap-card__rating-star"
                  :class="{ '-selected': n <= item.rating.score }"
                >
                  <svg
                    aria-hidden="true"
                    height="13px"
                    viewBox="0 0 42 40"
                    width="13px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                      <path
                        d="M32.1840261,37.568991 L28.3704261,25.1809655 L39.231509,16.2587574 L25.798253,16.1621556 L21,2.93285568 L16.201747,16.1621556 L2.76849099,16.2587574 L13.6295739,25.1809655 L9.81597389,37.568991 L21,29.957571 L32.1840261,37.568991 Z"
                        stroke-width="2"
                      ></path>
                    </g>
                  </svg>
                </span>
              </div>

              <span
                class="oap-card__rating-average"
                :class="{ '-mobile-visible': displayReviewCount }"
              >
                <span class="isSrOnly" v-text="item.rating.srText" />
                {{ item.rating.score }}/{{ item.rating.max }} {{ ratingCountText }}
              </span>
            </template>
          </div>

          <div v-if="item.action || $slots.favorite" class="oap-card__action">
            <slot name="favorite" />
            <a
              v-if="item.action && item.action.service && !channelAdvisorData"
              :href="item.action.service.url"
              :target="item.action.service.target"
              class="oap-button oap-button__service oap-card__cta -primary -theme-brand"
            >
              <img
                v-if="item.action.service.icon"
                :src="item.action.service.icon"
                alt=""
                class="oap-button__img oap-card__service-icon"
              />

              <span class="oap-button__label">
                {{ item.action.service.text }}
              </span>
            </a>
            <a
              v-if="item.action && !channelAdvisorData"
              v-tag:useractionevent="item.action.tag"
              :class="[
                'oap-button',
                'oap-card__cta',
                item.ctaClasses ? item.ctaClasses : '-secondary',
              ]"
              :href="
                item.buyNowUrl
                  ? item.buyNowUrl
                  : selectiveRetailerURL
                  ? `${item.url}${selectiveRetailerURL}`
                  : item.url
              "
              :target="item.action.target"
              :aria-label="[
                (item.buyNowUrl ? item.action.buyNowText : item.action.text) + ' ' + item.title,
              ]"
            >
              <span class="oap-button__label">
                <!-- if there is a `buyNowUrl` entity that means the text in dictionary will be provided as well -->
                {{ item.buyNowUrl ? item.action.buyNowText : item.action.text }}
              </span>

              <svg v-if="item.action.icon" aria-hidden="true" class="oap-card__action-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  :xlink:href="`#${item.action.icon}`"
                ></use>
              </svg>
            </a>
            <slot v-if="channelAdvisorData" name="cta-c-adv" />
          </div>
        </footer>
      </div>
      <div v-if="flipContent.exists" class="oap-card__back">
        <p class="oap-card__back-title">{{ flipContent.title }}</p>
        <p class="oap-card__back-description">{{ flipContent.description }}</p>
        <a
          ref="back-link"
          v-tag:useractionevent="flipContent.tagging"
          class="oap-card__back-link oap-button -secondary"
          :href="flipContent.link.url"
          :class="item.additionalLinkClass"
        >
          <span class="oap-button__label">{{ flipContent.link.label }}</span>
          <svg class="oap-button__icon">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              :xlink:href="`#${flipContent.link.icon}`"
            ></use>
          </svg>
        </a>
      </div>
    </div>
  </article>
</template>

<script>
import MediaImage from '../../../../MediaImage/code/Scripts/components/MediaImage.vue';
import OapBadge from '../../../../OapBadge/code/Scripts/components/OapBadge.vue';
import OapProductShadeList from '../../../../OapProductShadeList/code/Scripts/components/OapProductShadeList.vue';
import { once } from '@Foundation';
import { tag } from '@Foundation/analyticsHandler/directives/tag';

const settings = {
  ANALYTICS_ARGS_ARRAY: ['productclick', 'productimpression'],
  USER_ACTION_EVENT: 'useractionevent',
  INTERSECTION_CLASS: 'is-intersecting',
  INTERSECTION_TIMEOUT: 300,
  LG_SCREEN_START: 1025,
  MD_SCREEN_START: 512,
  WINDOW_INNER_WIDTH: window.innerWidth,
};

export default {
  name: 'OapCard',

  components: {
    MediaImage,
    OapBadge,
    OapProductShadeList,
  },

  props: {
    displayReviewCount: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String,
      default: 'default',
    },
    layouts: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    headerTitle: {
      type: String,
      default: '',
    },
    productsfullSearchResult: {
      default: false,
      type: Boolean,
      required: false,
    },
    positionOnGrid: {
      type: Number,
      default: null,
    },
    promoCardItems: {
      type: Array,
      default: () => [],
    },
    channelAdvisor: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      playing: false,
      selectiveRetailerURL: false,
      position: this.positionOnGrid,
    };
  },

  computed: {
    hasFooterItem() {
      return (
        this.item.price ||
        this.item.rating ||
        this.item.action ||
        (Object.keys(this.$slots).length && this.$slots?.favorite())
      );
    },

    flipContent() {
      const exists = Boolean(
        Object.keys(this.item).length &&
          Object.prototype.hasOwnProperty.call(this.item, 'flipContent')
      );
      return {
        exists,
        ...(exists && {
          ...this.item?.flipContent,
        }),
      };
    },

    layoutsClasses() {
      return [
        ...this.layouts,
        this.item.inset && 'inset',
        this.flipContent.exists && 'flipping',
        this.item.label && !this.headerTitle && this.item.gradient && 'outlined-gradient',
        this.item.label && !this.item.inset && !this.item.gradient && 'outlined',
      ]
        .filter(Boolean)
        .map((layout) => `oap-card--layout-${layout}`);
    },

    ratingCountText() {
      if (!this.displayReviewCount || !this.item.rating.count) return '';

      if (this.item.rating.count >= 10_000) {
        return '(10K+)';
      }

      return `(${this.item.rating.count})`;
    },

    tagging() {
      if (!this.item.tag) return;

      const tagging = {};

      // For non product related tagging
      // (structure of tagging data in card is different)
      if (this.item.tag.useractionevent) {
        tagging[settings.USER_ACTION_EVENT] = {
          ...this.item.tag.useractionevent,
        };
      } else {
        // For product related tagging
        settings.ANALYTICS_ARGS_ARRAY.forEach((eventType) => {
          tagging[eventType] = {
            ...this.item.tag.common.data,
            ...this.item.tag[eventType].data,

            products: [
              {
                ...this.item.tag.common.product,
                ...this.item.tag[eventType].product,
                price:
                  parseFloat(this.item.tag.common.product.price) !== 0
                    ? this.item.tag.common.product.price?.trim()
                    : 'none',
              },
            ],
          };
          if (eventType === 'productimpression') {
            tagging.productimpression.label = window.wsf.AnalyticsVariables.pageCategory;
            tagging.productimpression.name = 'view_item_list';
          }
          if (eventType === 'productclick') {
            tagging.productclick.name = 'select_item';
          }
        });
      }
      return tagging;
    },

    showBrand() {
      return !!this.item.brand && !this.layouts.includes('services-hub');
    },

    linkTitle() {
      return `${this.item?.brand ?? ''} ${this.item?.title ?? ''}`.trim();
    },

    isTypeProduct() {
      return this.item.type === 'product' || this.type === 'product';
    },

    price() {
      if (!this.item.price) {
        return null;
      } else {
        return this.item.price.replace(/\s/g, '');
      }
    },

    hasSecondPromoCard() {
      return this.promoCardItems.length && this.promoCardItems[1] ? true : false;
    },

    hasThirdPromoCard() {
      return this.promoCardItems.length && this.promoCardItems[2] ? true : false;
    },

    channelAdvisorData() {
      let channelAdvisorObj = this.channelAdvisor !== null || this.channelAdvisor !== undefined;
      return channelAdvisorObj ? this.channelAdvisor : false;
    },
  },

  mounted() {
    let url = new URL(window.location.href);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let paramsValue =
      params.utm_medium == 'qrcode' &&
      (params.utm_source == 'pos' || params.utm_retailer.length >= 1);
    if (paramsValue) {
      const getQueryString = url.search;
      this.selectiveRetailerURL = getQueryString;
    }
    let utmMedium_NoFilter = params.utm_nofilter == 'true';
    let filterPanerClass = document.querySelector('.filterList__panelWrapper');
    if (utmMedium_NoFilter && filterPanerClass) {
      filterPanerClass.classList.add('noFilter');
    }

    this.handleAnalytics();
    if (this.item.video) {
      this.$nextTick(() => {
        if (settings.WINDOW_INNER_WIDTH < settings.MD_SCREEN_START) {
          this.observeIntersection();
        } else if (settings.WINDOW_INNER_WIDTH >= settings.LG_SCREEN_START) {
          this.attachEvent('mouseover');
        }
      });
    }
  },

  methods: {
    gsqTagging() {
      let eanId = this.item.tag.product?.id;
      if (window.gsqEnable && this.productsfullSearchResult == 'true' && eanId) {
        let _gsq = window._gsq || [];
        _gsq.push([
          'trackSearchDetail',
          {
            ' c_pro_sku ': eanId,
          },
        ]);
      }
    },
    handlePlayingLogic() {
      this.pauseIfPlaying();
      this.togglePlaying();
    },

    pauseIfPlaying() {
      if (this.playing) {
        this.$refs.video.pause();
      }
    },

    togglePlaying() {
      this.playing = !this.playing;
    },

    attachAnalyticsDirective(eventType) {
      if (this.$refs.link) {
        tag.mounted(this.$refs.link, {
          arg: eventType,
          value: this.tagging[eventType],
        });
      }
    },

    handleAnalytics() {
      if (this.tagging) {
        if (this.item.tag.useractionevent) {
          this.attachAnalyticsDirective(settings.USER_ACTION_EVENT);
        } else {
          settings.ANALYTICS_ARGS_ARRAY.forEach((eventType) => {
            if (this.tagging[eventType]) {
              this.attachAnalyticsDirective(eventType);
            }
          });
        }
      }
    },

    observeIntersection() {
      const observer = new MutationObserver(() => {
        /* istanbul ignore next */
        setTimeout(() => {
          this.handlePlayingLogic();
          this.$nextTick(() => {
            if (this.$refs.card.classList.contains(settings.INTERSECTION_CLASS)) {
              this.$refs.video.play();
            } else {
              this.pauseIfPlaying();
            }
          });
        }, settings.INTERSECTION_TIMEOUT);
      });

      observer.observe(this.$refs.card, { attributes: true });
    },

    attachEvent(eventName) {
      once(this.$refs.link, eventName, ({ type }) => {
        this.handlePlayingLogic();
        this.$nextTick(() => {
          if (type === 'mouseover') {
            this.$refs.video.play();
            this.attachEvent('mouseout');
          } else if (type === 'mouseout') {
            this.attachEvent('mouseover');
          }
        });
      });
    },
  },
};
</script>
