<template>
  <div class="oap-video">
    <!--  Optional slot for inner placeholder -->
    <div
      v-show="showPlaceholder"
      :key="placeholderBackgroundKey"
      v-background="placeholderBackgroundProxy"
      class="placeholder bg-placeholder"
      aria-hidden="true"
      :class="placeholderClass"
      @click="togglePlaying"
    ></div>
    <div v-if="hasSources && videoSrc" class="oap-video__container">
      <video
        :key="videoSrc"
        ref="video"
        :src="lazyLoadTrigger !== 'intersecting' && videoSrc"
        :data-src="videoSrc"
        :class="{
          '-wide': isVideoWide,
        }"
        v-bind="{ autoplay: isAnimationEnabled, 'data-autoplay': isAnimationEnabled }"
        :playsinline="playsinline"
        :loop="loop"
        :muted="muted"
        type="video/mp4"
        preload="metadata"
        @click="togglePlaying"
      ></video>
      <button
        v-if="controls"
        ref="controls"
        class="oap-video__control"
        :class="`oap-video__control--is-${controlsPosition}`"
        :aria-pressed="playing.toString()"
        :title="playButtonTitle"
      >
        <span class="oap-video__control-overlay">
          <img :src="playButtonSrc" :alt="playButtonTitle" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { AnalyticsHandler, intersectionViewportObserver } from '@Foundation';
import eventBus from '@loreal/eventbus-js';
import { makeVideoAccessible } from '../utilities/makeVideoAccessible';
import { mapGetters } from 'vuex';
import {
  HERO_VIDEO_UPDATE,
  HERO_VIDEO_PAUSE,
  HERO_VIDEO_TOGGLE_PLAYING,
  HERO_VIDEO_BACKGROUND_PLAYING,
} from '@Feature/Filter/code/Scripts/constants/eventNames';
import { uniqueId } from '../utilities/uniqueId';
import {
  ACCESSIBILITY_STORE_NAMESPACE,
  GET_SITEWIDE_ANIMATIONS,
} from '@Foundation/accessibilityStore/accessibilityStore';

const TUTORIAL_VIDEO = 'Tutorial';

export default {
  name: 'OapVideo',

  props: {
    sources: { type: Object, default: null },

    gaCategory: { type: String, default: '' },
    gaLabel: { type: String, default: '' },
    eventName: { type: String, default: 'video_interaction' },
    linkUrl: { type: String, default: '' },

    placeholderBackground: { type: Object, default: null },
    playButtonTitle: { type: String, default: 'play or pause video' },

    placeholderClass: { type: Array, default: () => [] },

    autoplay: { type: Boolean, default: true },
    playsinline: { type: Boolean, default: true },
    loop: { type: Boolean, default: true },
    muted: { type: Boolean, default: true },
    controls: { type: Boolean, default: true },
    controlsPosition: { type: String, default: 'top-right' },

    pauseIfLeftViewport: { type: Boolean, default: false },
    playIfIsInViewportOnMobile: { type: Boolean, default: false },
    isInformative: { type: Boolean, default: false },
    videoTitle: { type: String, default: '' },

    lazyLoadTrigger: {
      type: String,
      default: 'eager',
      validator: function (value) {
        return ['eager', 'click', 'intersecting'].includes(value);
      },
    },

    uniqueId: { type: String, default: '' },
  },

  data() {
    return {
      lgScreenMediaQuery: 721,
      playing: this.autoplay,
      forcePlay: false,
      isLazyLoaded: false,
      sourcesProxy: {},
      placeholderBackgroundProxy: {},
      placeholderBackgroundKey: uniqueId(),
      autoplayProxy: this.autoplay,
      showPlaceholder: true,
      shouldPlayAsBackground: true,
    };
  },

  computed: {
    ...mapGetters('filterList', ['getFilterDataset']),
    isAnimationEnabled() {
      return this[GET_SITEWIDE_ANIMATIONS]() && this.autoplayProxy;
    },
    isVideoWide() {
      return this.videoSrc === this.filteredSourceProxies?.lg;
    },

    isLargeScreen() {
      return window.innerWidth > this.lgScreenMediaQuery;
    },

    videoSrc() {
      return this.isLargeScreen
        ? this.filteredSourceProxies?.lg
        : this.filteredSourceProxies?.sm + this.shouldShowFrame;
    },

    playButtonSrc() {
      return this.playing
        ? '/frontend/static/images/media-gallery-pause.svg'
        : '/frontend/static/images/media-gallery-play.svg';
    },

    hasSources() {
      return this.filteredSourceProxies && Object.keys(this.filteredSourceProxies)?.length;
    },

    filteredSourceProxies() {
      if (this.sourcesProxy && Object.keys(this.sourcesProxy).length) {
        return Object.entries(this.sourcesProxy).reduce(
          (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
          {}
        );
      }
      return this.sources;
    },

    isOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    },

    // add fix for displaying video preview for iOs devices
    shouldShowFrame() {
      // sets #t=0.001 for iOS to display the first frame
      return this.isOS ? '#t=0.001' : '';
    },

    isTutorialVideo() {
      return this.gaLabel === TUTORIAL_VIDEO;
    },
  },

  watch: {
    playing(val) {
      val ? this.playVideo() : this.$refs.video.pause();
    },
    isAnimationEnabled(newValue) {
      if (!newValue) this.pauseEventHandler();
    },
  },

  created() {
    this.sourcesProxy = this.sources;
    this.placeholderBackgroundProxy = this.placeholderBackground;
    this.showPlaceholder = Boolean(
      this.placeholderBackgroundProxy && Object.keys(this.placeholderBackgroundProxy).length
    );
  },

  mounted() {
    if (this.sources === null || !Object.keys(this.sources).length) return;
    this.$refs?.controls?.addEventListener('click', this.togglePlaying);

    if (this.lazyLoadTrigger === 'eager') {
      this.load();
    }

    if (!this.loop) {
      this.$refs.video.addEventListener('ended', this.togglePlaying);
    }

    if (this.pauseIfLeftViewport) {
      intersectionViewportObserver(this.$el, {
        checkIsVisible: true,
        delay: 300,
        rootMargin: '0px',
        threshold: 0,
        shouldUnobserve: false,
        conditionCheckCallback: this.intersectionConditionCheck,
        callbackBeforeResolve: this.pauseEventHandler,
      });
    }

    if (this.lazyLoadTrigger === 'intersecting') {
      intersectionViewportObserver(this.$el, {
        checkIsVisible: true,
        delay: 300,
        rootMargin: '200px',
        threshold: 0,
        shouldUnobserve: true,
        callbackBeforeResolve: this.load,
      });
    }

    if (this.playIfIsInViewportOnMobile && window.innerWidth < this.lgScreenMediaQuery) {
      const videoContainer = this.$el.querySelector('.oap-video__container');
      intersectionViewportObserver(videoContainer, {
        threshold: 1.0,
        shouldUnobserve: false,
        conditionCheckCallback: (change) => change.isIntersecting,
        callbackBeforeResolve: this.playVideo,
      }).then(() => {
        if (this.isTutorialVideo) {
          this.onloadDataLayer();
        }
      });
    }

    /* istanbul ignore next */
    eventBus.on(HERO_VIDEO_PAUSE, this.pauseEventHandler);
    eventBus.on('oap-video::pause', this.pauseEventHandler);
    eventBus.on(HERO_VIDEO_UPDATE, this.changeVideo);
    eventBus.on(
      `${HERO_VIDEO_TOGGLE_PLAYING + (this.uniqueId ? '::' + this.uniqueId : '')}`,
      this.togglePlaying
    );
    eventBus.on(HERO_VIDEO_BACKGROUND_PLAYING, (shouldPlay) => {
      if (this.shouldPlayAsBackground) {
        shouldPlay ? this.$refs.video.play() : this.$refs.video.pause();
      } else {
        this.$refs.video.pause();
      }
    });

    makeVideoAccessible(this.$refs.video, this.isInformative, this.videoTitle);
  },

  beforeUnmount() {
    if (this.$refs?.controls) {
      this.$refs?.controls?.removeEventListener('click', this.togglePlaying);
    }
  },

  methods: {
    ...mapGetters(ACCESSIBILITY_STORE_NAMESPACE, [GET_SITEWIDE_ANIMATIONS]),
    load() {
      if (this.$refs.video) {
        this.$refs.video.src = this.$refs.video.dataset.src;
      }
      this.isLazyLoaded = true;
    },

    togglePlaying() {
      this.pushAnalytics();
      if (this.sources?.sm || this.sources?.lg) {
        this.playing = !this.playing;
        this.shouldPlayAsBackground = !this.shouldPlayAsBackground;
      }
    },

    pauseEventHandler() {
      if (!this.forcePlay && this.playing) {
        this.$refs.video.pause();
        this.shouldPlayAsBackground = false;
        this.playing = false;

        this.$el.closest('.oap-attraction-hero')?.classList.add('-pause');
      }
    },

    playVideo() {
      if (!this.isLazyLoaded) {
        this.load();
      }
      this.forcePlay = true;
      eventBus.emit('oap-video::pause');
      this.forcePlay = false;
      this.showPlaceholder = false;
      this.$refs.video.play();
      this.playing = true;
      this.shouldPlayAsBackground = true;
    },

    changeVideo() {
      const banner = this.getFilterDataset?.banner;

      if (!banner) return;

      const { video, background } = banner;

      if (video && video?.sources) {
        this.sourcesProxy = video.sources;

        if (this.autoplayProxy) this.playing = true;
      } else {
        this.sourcesProxy = {};
        this.autoplayProxy = false;
      }

      if (background) {
        this.placeholderBackgroundProxy = background;
        this.placeholderBackgroundKey = uniqueId();
      }
    },

    intersectionConditionCheck(change) {
      return change.isIntersecting && this.playing && !this.autoplayProxy;
    },

    pushAnalytics() {
      const state = this.playing ? 'pause' : 'play';
      let tag = {
        event_name: 'video_interaction',
        type: 'userActionEvent',
        ecommerce: 'undefined',
        category: this.gaCategory,
        action: state,
        label: this.gaLabel,
      };

      if (this.isTutorialVideo) {
        tag.link_url = this.linkUrl;
      } else {
        tag.video_title = this.videoTitle;
        tag.video_action = state;
      }

      try {
        AnalyticsHandler.getAnalyticsHandler().push(tag);
      } catch (e) {
        /* istanbul ignore next */
        console.warn('Could not push to dataLayer', e);
      }
    },

    onloadDataLayer() {
      const tag = {
        event_name: this.eventName,
        type: 'nonInteractiveEvent',
        category: this.gaCategory,
        action: 'video started',
        label: this.gaLabel,
        link_url: this.linkUrl,
      };

      try {
        AnalyticsHandler.getAnalyticsHandler().push(tag);
      } catch (e) {
        /* istanbul ignore next */
        console.warn('Could not push to dataLayer', e);
      }
    },
  },
};
</script>
